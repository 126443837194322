<template>
  <div class="wl-home">
    <div class="w">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in store.topicsDoc"
          :key="index"
          :label="item.name"
          :name="item.id + ''"
        >
        </el-tab-pane>
      </el-tabs>
      <el-row :gutter="13">
        <el-col :span="6" v-for="(item, index) in contList" :key="index">
          <cont-item :it="item"></cont-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Store from '../../store';
import ContItem from './cont-item.vue';
export default {
  components: { ContItem },
  mounted() {
    // this.getDocDet();
    this.getVideoStateList();
  },

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      contList: [
        // {
        //   address	   :'',//地址	string
        //   descText	 :'',//描述说明	string
        //   icon	     :'',//图标	string
        //   id	       :'',//id 新增时不传入	integer(int64)
        //   name    	 :'',//名称	string
        //   networkName:'',//网络	string
        //   sortNum	   :'',//排序值 越小越靠前	integer(int32)
        //   typeCode  :'',//类型 1.token 2.功能合约	integer(int32)
        // }
      ],
      activeName: '1549654715992772608',
      act: '',
      docList: [],
      total: 0,
      pageNum: 1,
      pageSize: 100,
    };
  },
  methods: {
    // 选择某一个选项
    handleClick(tab) {
      this.act = this.store.topicsDoc[tab.index].id;
      // 如果是精品
      this.getDocDet();
      window.sessionStorage.setItem('contAct', this.act);
    },
    // 获取文档分类
    async getVideoStateList() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(`/contract/network/list`);
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      // console.log(res.data);
      this.store.topicsDoc = res.data;
      this.activeName =
        window.sessionStorage.getItem('contAct') || res.data[0].id;
      this.act = window.sessionStorage.getItem('contAct') || res.data[0].id;
      this.getDocDet();
    },
    // 获取地址列表
    async getDocDet() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(
        `/contract/type/list/${this.act}`
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      // console.log(res.data);
      this.contList = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.wl-home {
  // height: 600px;
  // margin-top: 200px;
  padding-bottom: 50px;
  min-height: 600px;
}
</style>
