<template>
  <div class="box-item">
    <div class="logo">
      <img :src="it.icon" alt="" />
      {{ it.name }}
    </div>
    <div class="network">网络: {{ it.networkName }}</div>
    <div class="network">
      合约: <span>{{ uti.shrink(it.address, 10) }}</span>
    </div>
    <a href="javascript:;">
      <!-- <div class="but" @click="addToken()">添加代币</div> -->
      <div
        class="but but2 hash"
        @click="doCopy()"
        :data-clipboard-text="it.address"
        v-if="copy"
      >
        已复制
      </div>
      <div
        v-else
        class="but hash"
        @click="doCopy()"
        :data-clipboard-text="it.address"
      >
        复制
      </div>
    </a>
  </div>
</template>
<script>
import Store from '../../store';
import Clipboard from 'clipboard';
export default {
  props: {
    it: {
      type: Object,
      default() {
        return {
          address: '', //地址	string
          descText: '', //描述说明	string
          icon: '', //图标	string
          id: '', //id 新增时不传入	integer(int64)
          name: '', //名称	string
          networkName: '', //网络	string
          sortNum: '', //排序值 越小越靠前	integer(int32)
          typeCode: '', //类型 1.token 2.功能合约	integer(int32)
        };
      },
    },
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      copy: false,
    };
  },
  methods: {
    // 给metamask添加代币
    async addToken() {
      // const tokenAddress = "0xd00981105e61274c8a5cd5a88fe7e037d935b513";
      // const tokenSymbol = "TUT";
      // const tokenDecimals = 18;
      // const tokenImage = require("../../assets/images/logo.png");

      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: this.it.address, // The address that the token is at.
              symbol: this.it.symbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: this.it.decimals, // The number of decimals in the token
              image: this.it.image, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          console.log('Thanks for your interest!');
        } else {
          console.log('Your loss!');
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 向metamask添加psc网络
    async addNetwork() {
      await window.ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              nativeCurrency: {
                name: this.store.actionNetworkOne.network_name,
                symbol: this.store.actionNetworkOne.network_symbol, // 2-6 characters long
                decimals: this.store.actionNetworkOne.network_decimal,
              },
              // 链id
              chainId: this.web3.utils.numberToHex(
                this.store.actionNetworkOne.chain_id
              ),
              // 链名称
              // chainName: "bsc",
              chainName: this.store.actionNetworkOne.network_name,
              // 链节点
              // rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
              rpcUrls: [this.store.actionNetworkOne.rpc_url],

              // 浏览器网站
              blockExplorerUrls: [this.store.actionNetworkOne.scan_url],
            },
          ],
        })
        .then((res) => {
          this.switchNetWork();
          console.log(res + '添加成功');

          // return true;

          //添加成功
        })
        .catch((err) => {
          // console.log("添加失败");
          console.log(err);
          //添加失败
        });
    },

    // 分享，复制链接
    doCopy() {
      // console.log(cp);
      var clipboard = new Clipboard('.hash');
      // console.log(clipboard);
      clipboard.on('success', () => {
        this.$message.success('合约地址已复制');
        this.copy = true;
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        // 不支持复制
        this.$message.error('error');
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box-item {
  /* 矩形 6 */
  /* 矩形 6 */
  // width: 350px;
  // height: 310px;

  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  margin-bottom: 33px;
  padding: 29px 32px;
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    font-weight: bold;
  }
  .network {
    /* 0x101D4507E0c07Aa...eabcB7bcAef5e391 */

    height: 20px;
    font-family: SourceHanSansCN-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
    color: #999999;
    margin-top: 28px;
    span {
      color: #ff7d41;
    }
  }
  .but {
    /* 矩形 15 */
    text-align: center;
    margin-top: 30px;
    height: 50px;
    border-radius: 5px;
    background: #ff7d41;
    /* 点击复制合约 */
    line-height: 50px;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #ffffff;
  }
  .but2 {
    color: #ff7d41;
    background-color: #fff;
    border: 1px solid #ff7d41;
  }
}
</style>
